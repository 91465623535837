/**
 * Formats the time slots and returns a string and count of available time slots.
 *
 * @param {object} shippingMethod .
 * @returns {null} .
 */
export const timeSlotStringAndCount = (timeSlots) => {
  if (timeSlots && timeSlots?.length > 0) {
    const formattedSlots = timeSlots?.map((slot) => {
      /**
       *
       * @param {string} time .
       * @returns {string} formate time
       */
      const sliceTime = (time) => time.slice(0, 5);
      return `${sliceTime(slot.startTime)} - ${sliceTime(slot.endTime)} hrs`;
    });
    return {
      timeSlotsString: formattedSlots?.join(", ") || "",
      timeSlotCount: timeSlots?.length || null,
    };
  }
  return null;
};

/**
 * This method is used display time lapse clock
 *
 * @param {number} timer timestamp.
 * @returns {string} modified object
 */
export const clock = (timer) => {
  const delta = Math.floor(timer / 1000);
  const hours = Math.floor(delta / 3600);
  const minutes = Math.floor((delta - 3600 * hours) / 60);
  const seconds = delta - 3600 * hours - 60 * minutes;
  const displayTime = `${(hours < 10 ? "0" : "") + hours}:${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
  return displayTime;
};

/**
 * This function calculates the time left until a given end date.
 *
 * @param {string} endDate - The end date.
 *  @returns {object} An object with the time left in hours, minutes, and seconds.
 */
export const calculateTimeLeft = (endDate) => {
  const now = new Date();
  const end = new Date(endDate);
  const difference = end - now;

  let timeLeft = { hours: 0, minutes: 0, seconds: 0 };

  if (difference > 0) {
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    timeLeft = {
      hours,
      minutes,
      seconds,
    };
  }

  return timeLeft;
};

/**
 * Get the time elapsed since the given timestamp.
 * @param {number} timestamp - The timestamp in milliseconds.
 * @returns {string} - Time elapsed string.
 */
export const getTimeAgo = (timestamp) => {
  const reviewedTimeStamp = new Date(timestamp);
  const currentTimestamp = Date.now();
  const timeDifference = currentTimestamp - reviewedTimeStamp;

  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  } else if (months > 0) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else if (weeks > 0) {
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (days > 0) {
    return `${days} day${days > 1 ? "s" : ""} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? "s" : ""} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
  } else {
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  }
};
