import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import PopOver from "../../atoms/popOver";
import Image from "next/image";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { requestToFetchProductListingAction } from "../../../store/slices/plpSlice";
import { COUNTRIES, fnpPageType } from "../../../constants/common/common";
import { plpCleverTapEvents, plpPageConstants } from "../../../constants/pageConstants/plpConstants";
import { getReplaceURL } from "./helper";
import useAnalytics from "../../../analytics/useAnalytics";
import { GA4_EVENTS } from "../../../analytics/gaConstant";
import { usePathname } from "next/navigation";

/**
 * Component for sorting items with a popover menu.
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.data - The data array containing items to be sorted.
 * @returns {JSX.Element} - The SortDesktop component JSX element.
 */
export default function SortDesktop({ data, categoryId }) {
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [activeButton, setActiveButton] = useState(plpPageConstants.RECOMMENDED);
  const buttonRef = useRef(null);
  const popoverRef = useRef(null);

  const dispatch = useDispatch();
  const { appliedFilters, appliedSort, pincode, intPincode } = useSelector(
    (state) => ({
      appliedFilters: state?.plp?.appliedFilters,
      appliedSort: state?.plp?.appliedSort,
      pincode: state?.locationLock?.selectedDomesticLocation?.pincode,
      intPincode: state?.locationLock?.selectedIntLocation?.pincode,
    }),
    shallowEqual,
  );

  const catalogId = useSelector((state) => state.appConfig.catalogId);

  useEffect(() => {
    if (appliedSort?.length > 0) {
      setActiveButton(data?.filter((item) => item.value === appliedSort)[0]?.level);
    }
  }, [appliedSort]);

  /**
   * Handles outside click events and hides the popover if the click is outside the specified elements.
   * @param {Object} event - The click event object.
   */
  const handleOutsideClick = (event) => {
    if (
      isPopoverVisible &&
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      !buttonRef.current.contains(event.target)
    ) {
      setIsPopoverVisible(false);
    }
  };

  // Close the popover on outside click or scroll
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPopoverVisible]);

  /**
   * Toggles the visibility of the popover menu.
   */
  const onPopover = () => setIsPopoverVisible((prevState) => !prevState);

  /**
   * Handles button click events and sets the active button.
   * @param {Object} item - The item object representing the clicked button.
   */
  const onButtonClick = (event) => {
    event.stopPropagation();
    const { id, innerText } = event.target;
    setActiveButton(innerText);
    onPopover();
    if (id) {
      const urlParams = new URLSearchParams(window?.location?.search);
      const qs = urlParams.has("qs") ? urlParams.get("qs") : null;
      const newUrl = getReplaceURL(urlParams, id);
      window.scrollTo(0, 0);

      const commonParams = {
        page: 0,
        size: plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
        geoId: catalogId || COUNTRIES?.INDIA,
        sortOption: encodeURIComponent(id),
      };

      const actionParams = qs
        ? { ...commonParams, pageType: plpPageConstants.SEARCH, qs }
        : {
            ...commonParams,
            categoryUrl: `fnp.com/${categoryId}`,
            pincode: catalogId !== COUNTRIES.INDIA ? intPincode : pincode,
            filters: encodeURIComponent(appliedFilters),
          };
      dispatch(requestToFetchProductListingAction(actionParams));
      trackEvent(
        plpCleverTapEvents.SORT_SELECTED,
        {
          page_name: pathname,
          page_type: fnpPageType.CATEGORY,
          updated_sort: innerText,
        },
        ["clevertap"],
      );
      trackEvent(GA4_EVENTS.FILTER_CLICKED, { active_filters: null, selected_filter: innerText }, ["ga4"]);
      window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
    }
  };

  return (
    <div
      ref={popoverRef}
      className={`relative flex cursor-pointer justify-between rounded-xl border-1 px-12 py-[5px] ${isPopoverVisible ? "rounded-b-none border-b-0" : ""}`}
      onMouseDown={onPopover}
    >
      <button className="text-14" ref={buttonRef}>
        <span className="text-14 font-600 text-grey-400">Sort by :</span>
        <span className="mr-8 font-500"> {activeButton}</span>
      </button>
      <PopOver
        triggerRef={buttonRef}
        isVisible={isPopoverVisible}
        onTogglePopover={onPopover}
        isBackdrop={false}
        parentClass="w-full left-[0px] top-[100%] z-2 absolute"
        classes="py-4 relative w-full left-0 rounded-t-none border-t-0"
        isShowPageScroll={true}
      >
        <div className="flex flex-col items-start justify-start">
          {data?.map((item) => (
            <button
              id={item.value}
              key={item.value}
              className={`w-full px-12 py-8 text-left ${activeButton === item.level ? "font-medium text-fnp-500" : "text-grey-500"} last:rounded-b-2xl hover:bg-grey-100 last:hover:rounded-b-2xl`}
              onMouseDown={onButtonClick}
            >
              <span id={item.value} className="text-14">
                {item.level}
              </span>
            </button>
          ))}
        </div>
      </PopOver>
      <span className="flex w-[15px] items-center">
        <Image
          className={isPopoverVisible ? "rotate-180" : "rotate-0"}
          src="/icons/navigation-chevron.svg"
          alt={`filter-chevron_icon`}
          width={15}
          height={15}
        />
      </span>
    </div>
  );
}

SortDesktop.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
    }),
  ).isRequired,
  categoryId: PropTypes.string.isRequired,
};
