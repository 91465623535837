/* eslint-disable react/jsx-boolean-value */
import PropTypes from "prop-types";
import React from "react";
import CustomImage from "../../atoms/customImage";
import { formatToK } from "../../../utils/common/";
import { BEST_RATING } from "../../../constants/common/common";

/** */
const RateAndReview = ({
  review,
  isReviewTextHide = false,
  classes = "absolute left-8 z-1",
  isSmallReviewTag = false,
  isShowTotalReviews = true,
  isPriority = false,
}) => {
  return (
    <div
      itemProp="aggregateRating"
      itemScope
      itemType="https://schema.org/AggregateRating"
      role="presentation"
      className={`${classes} bottom-8 flex w-max items-center gap-4 rounded-24 bg-white-900 bg-opacity-70 px-10 py-4 font-500 leading-0 text-fnp-500 ${isSmallReviewTag ? "text-10" : "text-12 xxl:text-14"}`}
    >
      <CustomImage
        src="/icons/vector-rating.svg"
        width={isSmallReviewTag ? 7 : 10}
        height={isSmallReviewTag ? 7 : 10}
        alt="rating star"
        isPriority={isPriority}
      />
      {review?.ratingValue}
      {isShowTotalReviews && (
        <>
          <span className="h-[10px] w-[0.5px] bg-grey-200" />{" "}
          <span itemProp="reviewCount" className="text-gray-500">
            {review?.reviewCount
              ? formatToK(review?.reviewCount, isReviewTextHide)
              : formatToK(review?.ratingCount, isReviewTextHide)}
          </span>
        </>
      )}
      <div itemProp="bestrating" className="hidden">
        {BEST_RATING}
      </div>
    </div>
  );
};

RateAndReview.propTypes = {
  review: PropTypes.object.isRequired,
  isReviewTextHide: PropTypes.bool,
  classes: PropTypes.string,
  isSmallReviewTag: PropTypes.bool,
  isShowTotalReviews: PropTypes.bool,
  isPriority: PropTypes.bool,
};

export default RateAndReview;
