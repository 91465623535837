import PropType from "prop-types";
import { useCallback, useEffect, useState } from "react";
import Typography from "../../atoms/typography";
import FilterSection from "./filterSection/index";
import { getReplaceFiltersURL, removeFiltersParamsFromURL } from "./helper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { requestToFetchProductListingAction } from "../../../store/slices/plpSlice";
import { plpCleverTapEvents, plpPageConstants } from "../../../constants/pageConstants/plpConstants";
import { COUNTRIES, fnpPageType } from "../../../constants/common/common";
import { convertFilterObjToArray, toTitleCase } from "../../../utils/common";
import useAnalytics from "../../../analytics/useAnalytics";
import { GA4_EVENTS } from "../../../analytics/gaConstant";
import { usePathname } from "next/navigation";

/**
 * ProductFilter component for displaying and managing product filters.
 * @param {object} param0 - The props object.
 * @returns {JSX.Element}
 */
const ProductFilter = ({ facets, categoryId }) => {
  const { trackEvent } = useAnalytics();
  const pathname = usePathname();
  const [selectedOptions, setSelectedOptions] = useState({});
  const dispatch = useDispatch();
  const [initialLoad, setInitialLoad] = useState(true);
  const urlParams = new URLSearchParams(typeof window === "object" ? window?.location?.search : "");
  const { appliedFilters, appliedSort, pincode, isLoaded, catalogId, intlPincode } = useSelector(
    (state) => ({
      appliedFilters: state?.plp?.appliedFilters,
      appliedSort: state?.plp?.appliedSort,
      pincode: state?.locationLock?.selectedDomesticLocation?.pincode,
      isLoaded: state?.plp?.isLoaded,
      catalogId: state.appConfig.catalogId,
      intlPincode: state?.locationLock?.selectedIntLocation?.pincode,
    }),
    shallowEqual,
  );

  /**
   *
   */
  const onSelectedOptions = useCallback((options, sortOption, selectedPincode, intlPincode) => {
    const appliedFilterArray = convertFilterObjToArray(options);
    window.scrollTo(0, 0);
    if (appliedFilterArray?.length > 0) {
      dispatch(
        requestToFetchProductListingAction({
          page: plpPageConstants.INITIAL_PAGE,
          size: plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
          geoId: catalogId || COUNTRIES?.INDIA,
          categoryUrl: `fnp.com/${categoryId}`,
          pincode: catalogId !== COUNTRIES?.INDIA ? intlPincode : selectedPincode,
          filters: encodeURIComponent(appliedFilterArray),
          sortOption: encodeURIComponent(sortOption),
        }),
      );
      setTimeout(() => {
        const newUrl = getReplaceFiltersURL(urlParams, appliedFilterArray);
        window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
      }, 100);
    } else {
      dispatch(
        requestToFetchProductListingAction({
          page: plpPageConstants.INITIAL_PAGE,
          size: plpPageConstants.DESKTOP_PRODUCT_PER_PAGE,
          geoId: catalogId || COUNTRIES?.INDIA,
          categoryUrl: `fnp.com/${categoryId}`,
          pincode: catalogId !== COUNTRIES?.INDIA ? intlPincode : selectedPincode,
          filters: [],
          sortOption: encodeURIComponent(sortOption),
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (isLoaded) {
      if (Object?.keys(selectedOptions)?.length < 1 && typeof window === "object" && !initialLoad) {
        const urlParams = new URLSearchParams(window?.location?.search);
        const newUrl = removeFiltersParamsFromURL(urlParams, "fq");
        window.history.replaceState({ ...window.history.state, as: newUrl, url: newUrl }, "", newUrl);
      }
    }
  }, [isLoaded, selectedOptions]);

  useEffect(() => {
    if (appliedFilters?.length > 0 && appliedFilters?.[0] !== "" && initialLoad) {
      setSelectedOptions(
        appliedFilters?.reduce((acc, item) => {
          const categoryValues = item?.split(":");
          return { ...acc, [categoryValues[1]]: `${categoryValues[0]}:${categoryValues[1]}` };
        }, {}),
      );
    }
  }, [appliedFilters]);

  useEffect(() => {
    if (!initialLoad) {
      onSelectedOptions(selectedOptions, appliedSort, pincode, intlPincode);
    }
  }, [selectedOptions]);

  /**
   * @param {*} event
   */
  const onOptionChange = (event) => {
    const { value, dataset } = event.target;
    setInitialLoad(false);
    if (selectedOptions[value]) {
      const option = { ...selectedOptions };
      delete option[value];
      setSelectedOptions({ ...option });
    } else {
      setSelectedOptions((prev) => ({
        ...prev,
        [value]: `${dataset.parentfilter}:${value}`,
      }));
      trackEvent(
        plpCleverTapEvents.FILTER_SELECTED,
        {
          page_name: pathname,
          page_type: fnpPageType.CATEGORY,
          filter: `${dataset?.parentfilter} : ${value}`,
        },
        ["clevertap"],
      );
      trackEvent(
        GA4_EVENTS.FILTER_CLICKED,
        { active_filters: [...appliedFilters, `${dataset.parentfilter} : ${value}`], selected_filter: value },
        ["ga4"],
      );
    }
  };

  /**
   * To Clear all the applied filters.
   */
  const onClearFilters = () => {
    setInitialLoad(false);
    setSelectedOptions({});
  };

  /**
   * Filtering the tabs which has more than zero filters available.
   */
  const getFilterTabs = () => {
    return facets?.map((filter) => {
      let totalCount = filter?.facetOptions?.reduce((acc, option) => {
        return acc + option?.count;
      }, 0);
      return (
        totalCount > 0 && (
          <FilterSection
            key={filter.facetFieldName}
            title={toTitleCase(filter?.facetFieldDisplayName)}
            options={filter.facetOptions}
            onOptionChange={onOptionChange}
            selectedOptions={selectedOptions}
            facetName={filter.facetFieldName}
          />
        )
      );
    });
  };

  return (
    <div className="border-grey-100 sticky top-148 max-h-[calc(100vh-22vh)] w-full max-w-md overflow-x-hidden rounded-lg border-1 bg-white-900 scrollbar-thin">
      <div className="relative flex items-center justify-between px-20 py-16">
        <Typography Tag="h6" variant="subheading1" classes="text-20 font-500 text-fnp-500">
          Filter
        </Typography>
        {selectedOptions && Object.keys(selectedOptions)?.length > 0 && (
          <Typography
            Tag="h6"
            variant="subheading1"
            classes="text-14 text-blue-100 font-500 cursor-pointer"
            onClick={onClearFilters}
          >
            Clear All
          </Typography>
        )}
      </div>
      <div className="mx-auto w-full">{getFilterTabs()}</div>
    </div>
  );
};

ProductFilter.propTypes = {
  categoryId: PropType.string.isRequired,
  facets: PropType.arrayOf(
    PropType.shape({
      facetFieldName: PropType.string.isRequired,
      facetFieldDisplayName: PropType.string.isRequired,
      facetOptions: PropType.arrayOf(
        PropType.shape({
          value: PropType.string.isRequired,
          displayName: PropType.string.isRequired,
          count: PropType.number.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
};

export default ProductFilter;
