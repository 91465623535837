//This is the PLP and Product related customers reviews carousel option
export const CUSTOMER_REVIEWS_OPTIONS = {
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        arrows: false,
      },
    },
  ],
};
//This is the PLP Banner carousel option
export const PLP_BANNERS_OPTIONS = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
        arrows: false,
      },
    },
  ],
};

//This is the PLP Banner carousel option
export const HOME_BANNERS_OPTIONS = {
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  // centerMode: true,
  arrows: false,
  autoplay: true,
  // className: "center",
  // centerPadding: "20px",
};
export const HOME_DESK_BANNERS_OPTIONS = {
  dots: true,
  infinite: false,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false,
  // centerMode: true,
  // className: "center",
  // centerPadding: "100px",
};
//This is the PLP Banner carousel option
export const HOME_CATEGORY_OPTIONS = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        arrows: false,
        infinite: false,
        dots: true,
      },
    },
  ],
};

//This is the PLP Banner carousel option
export const OLD_HOME_CATEGORY_OPTIONS = {
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 460,
      settings: {
        slidesToShow: 2.5,
      },
    },
  ],
};

export const HOME_OFFERS_OPTIONS = {
  dots: false,
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

export const HOME_SQUARE_BANNER_OPTIONS = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        arrows: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: false,
      },
    },
  ],
};

export const PRODUCT_CARD_OPTIONS = {
  dots: true,
  fade: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  autoplaySpeed: 1000,
};

export const PDP_SLIDER_OPTIONS = {
  dots: false,
  infinite: false,
  slidesToShow: 6,
  slidesToScroll: 1,
  vertical: true,
  verticalSwiping: true,
  autoplay: false,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        arrows: false,
        vertical: false,
        verticalSwiping: false,
        dots: true,
        autoplay: true,
        infinite: false,
      },
    },
  ],
};
