import { useEffect, useState } from "react";
import PropType from "prop-types";
import CheckboxItemList from "../../../molecules/checkboxItemList";
import Image from "next/image";
import { plpPageConstants } from "../../../../constants/pageConstants/plpConstants";
import isEmpty from "lodash.isempty";

/**
 * FilterSection component for handling each filter section.
 * @param {object} param0 - The props object.
 * @returns {JSX.Element}
 */
const FilterSection = ({ title, options, onOptionChange, selectedOptions, facetName }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isEmpty(selectedOptions)) {
      setIsOpen(false);
    }
  }, [selectedOptions]);

  /**
   * Toggle filters category on/off.
   */
  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  /**
   *
   * @returns
   */
  const getLabel = (option) => {
    const priceTo = option?.priceTo;

    if (option?.priceFrom && priceTo)
      return `${(parseFloat(option.priceFrom) || 0).toFixed()} ${
        priceTo?.trim() === plpPageConstants?.Asterisk
          ? plpPageConstants.AndAbove
          : `${plpPageConstants.TO} ${(parseFloat(priceTo) || 0).toFixed()}`
      } (${option.count})`;

    return `${option?.displayName} (${option.count})` ?? "";
  };

  /**
   * Calculates the number of selected options with a count greater than zero.
   *
   * @returns {number} The count of options that are selected and have a count greater than zero.
   */
  const handleCatergoryCount = () => {
    return options?.reduce(
      (countZ, option) => countZ + (option.count > 0 && !!selectedOptions?.[option.value] ? 1 : 0),
      0,
    );
  };

  return (
    <div className="border-t border-gray-100 px-20 py-16">
      <button
        className={`flex w-full items-center justify-between text-left focus:outline-none ${isOpen ? "mb-10" : ""}`}
        onClick={onToggle}
      >
        <span className={`text-14 font-500 ${handleCatergoryCount() > 0 ? "font-600" : "font-500"}`}>{title}</span>
        {isOpen ? (
          <Image src={`/icons/minus.svg`} alt="close" className="p-1" width={22} height={22} />
        ) : (
          <Image src={`/icons/add.svg`} alt="open" className="p-1" width={22} height={22} />
        )}
      </button>
      {isOpen &&
        options?.map(
          (option, index) =>
            option.count > 0 && (
              <div key={option.value} className="flex items-center py-10 text-14">
                <CheckboxItemList
                  id={`${title.replace(" ", "")}_${index}`}
                  label={getLabel(option)}
                  value={option.value}
                  onChange={onOptionChange}
                  data-parentfilter={facetName}
                  isChecked={!!selectedOptions?.[option.value]}
                  labelColor="text-grey-500 text-14"
                  labelPlacement="largeGap"
                />
              </div>
            ),
        )}
    </div>
  );
};

FilterSection.propTypes = {
  title: PropType.string.isRequired,
  options: PropType.array.isRequired,
  onOptionChange: PropType.func.isRequired,
  selectedOptions: PropType.object.isRequired,
  facetName: PropType.string.isRequired,
};

export default FilterSection;
