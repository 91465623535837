import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { plpBannerConstants } from "../../../constants/pageConstants/plpConstants";
import Toast from "../../../components/atoms/toast";
import Typography from "../../../components/atoms/typography";
import CustomImage from "../../../components/atoms/customImage";
import ButtonComponent from "../../../components/atoms/button";
import { constants } from "../../../constants/pageConstants/pdpConstants";
import { calculateTimeLeft } from "../../../utils/common/timeSlot";

/**
 * This component is for plp banner for campaigns which runs through cleverTap.
 *
 * @param {object} props props from parent
 * @param {object} props.plpBannerData  category path
 * @param {object} props.isMobile  boolean for mobile devices
 * @returns {React.Fragment} jsx for the component.
 */
const PlpBanner = ({ plpBannerData, isMobile }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [timeLeft, setTimeLeft] = useState({
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const offerEndDateAndTime = plpBannerData.offer_end_date_time;
  const offerDateTime = new Date(offerEndDateAndTime);
  const todayDate = new Date();
  const isValidOffer = offerDateTime >= todayDate;

  useEffect(() => {
    const timerId = setInterval(() => {
      const { hours, minutes, seconds } = calculateTimeLeft(offerEndDateAndTime);

      setTimeLeft({
        hours: String(hours).padStart(2, "0"),
        minutes: String(minutes).padStart(2, "0"),
        seconds: String(seconds).padStart(2, "0"),
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, [offerEndDateAndTime]);

  /**
   * This function is used to copy the coupon code when click on the copy code button.
   *
   */
  const onHandleCopyText = () => {
    if (window.isSecureContext && navigator.clipboard) {
      navigator.clipboard
        .writeText(plpBannerData.coupon_code_text)
        .then(() => {
          setIsTooltipVisible(true);
        })
        .catch((err) => err);
    }
  };

  /**
   *
   * @returns
   */
  const codeCopiedText = () => (
    <Toast
      variant={constants.SUCCESS}
      isToast={isTooltipVisible}
      header={plpBannerConstants.CODE_COPIED}
      duration={3000}
      position="fixed bottom-80"
      // eslint-disable-next-line react/jsx-handler-names
      onToastChange={() => setIsTooltipVisible(false)}
    />
  );

  return (
    isValidOffer && (
      <>
        <div
          className={`flex rounded-8 ${isMobile ? "mb-24 mt-16 justify-between px-14 py-12" : "my-26 justify-evenly pb-36 pt-32 "}`}
          style={{
            backgroundImage: `url(${isMobile ? plpBannerData.bg_image_mobile : plpBannerData.bg_image_desktop})`,
          }}
        >
          <div className="flex flex-col justify-center">
            <h1
              className={`${isMobile ? "text-16" : "text-56"} font-600`}
              style={{ color: plpBannerData?.h1_text_colour }}
            >
              {plpBannerData.h1_text}
            </h1>
            <h2
              className={`${isMobile ? "text-12" : "text-36 leading-[47px]"} font-400`}
              style={{ color: plpBannerData?.h2_text_colour }}
            >
              {plpBannerData.h2_text}
            </h2>
            {isMobile && (
              <Typography Tag="p" classes="flex mt-8">
                <span
                  className="text-14 font-400"
                  style={{
                    color: plpBannerData.use_code_colour,
                  }}
                >
                  {plpBannerConstants.USE_CODE}
                  {": "}
                  <strong style={{ color: plpBannerData.coupon_code_text_colour }}>
                    {plpBannerData.coupon_code_text}
                  </strong>
                </span>
                <CustomImage
                  width={14}
                  height={16}
                  className="ms-3 w-14"
                  src="/icons/copy-icon.svg"
                  alt="copy icon"
                  onClick={onHandleCopyText}
                />
              </Typography>
            )}
          </div>

          <div className="flex flex-col justify-center gap-4">
            <p
              className={`${isMobile ? "text-10 font-400" : "mb-18 text-18 font-500"} text-center`}
              style={{ color: plpBannerData.timer_text_colour }}
            >
              {plpBannerData.timer_heading_text}
            </p>
            <div className="flex gap-16">
              <div className={`flex flex-col ${isMobile ? "gap-4" : "gap-8"}`}>
                <span
                  className={`rounded-8 bg-black-900 ${isMobile ? "h-32 w-32 px-2 py-4 text-14" : "h-60 w-68 p-4 text-32"} text-center font-600 text-white-900`}
                >
                  {timeLeft.hours}
                </span>
                <p
                  className={`text-center ${isMobile ? "text-12" : "text-14"} font-500`}
                  style={{ color: plpBannerData.hrs_colour }}
                >
                  {plpBannerConstants.HRS}
                </p>
              </div>
              <div className={`flex flex-col ${isMobile ? "gap-4" : "gap-8"}`}>
                <span
                  className={`rounded-8 bg-black-900 ${isMobile ? "h-32 w-32 px-2 py-4 text-14" : "h-60 w-68 p-4 text-32"} text-center font-600 text-white-900`}
                >
                  {timeLeft.minutes}
                </span>
                <p
                  className={`text-center ${isMobile ? "text-12" : "text-14"} font-500`}
                  style={{ color: plpBannerData.min_colour }}
                >
                  {plpBannerConstants.MIN}
                </p>
              </div>
              <div className={`flex flex-col ${isMobile ? "gap-4" : "gap-8"}`}>
                <span
                  className={`rounded-8 bg-black-900 ${isMobile ? "h-32 w-32 px-2 py-4 text-14" : "h-60 w-68 p-4 text-32"} text-center font-600 text-white-900`}
                >
                  {timeLeft.seconds}
                </span>
                <p
                  className={`text-center ${isMobile ? "text-12" : "text-14"} font-500`}
                  style={{ color: plpBannerData.sec_colour }}
                >
                  {plpBannerConstants.SEC}
                </p>
              </div>
            </div>
          </div>

          {!isMobile && (
            <div className="flex flex-col justify-center gap-16">
              <Typography Tag="p">
                <span
                  className="text-24 font-400"
                  style={{
                    color: plpBannerData?.use_code_colour,
                  }}
                >
                  {plpBannerConstants.USE_CODE}
                  {": "}
                  <strong style={{ color: plpBannerData.coupon_code_text_colour }}>
                    {plpBannerData.coupon_code_text}
                  </strong>
                </span>
              </Typography>
              <ButtonComponent
                size="medium"
                classes={`text-base px-16 ${isMobile ? "my-12 mx-16" : ""}`}
                buttonVariant="filled"
                buttonTextSize="large"
                onClick={onHandleCopyText}
                label={plpBannerData.cta_text}
                style={{ color: plpBannerData.cta_text_colour, backgroundColor: plpBannerData.cta_bg_colour }}
              />
            </div>
          )}
        </div>
        {isTooltipVisible && codeCopiedText()}
      </>
    )
  );
};

PlpBanner.propTypes = {
  plpBannerData: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default PlpBanner;
